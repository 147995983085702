import { createSlice } from "@reduxjs/toolkit";
import { FacultyData } from "../components/pages/faculty/data";

const StaffReducer = createSlice({
  name: "staff_data",
  initialState: { FacultyData },
  reducers: {
    getData: (state, action) => {
      // console.log(state);
    },
  },
});
export const { getData } = StaffReducer.actions;
export default StaffReducer.reducer;
