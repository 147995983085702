import "./App.css";
import AdmissionBanner from "./components/admissionBanner";
import Main from "./components/main";
import Routers from "./routers";
import BubbleAnimate from "./components/bubbleAnimate";
import AOS from "aos";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { hydrate, render } from "react-dom";
// import BubbleAnimate from "./components/bubbleAnimate";
// Add this import to your React component or src/index.js
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  // const location = useLocation();
  // const pathUrl = location;
  // console.log(pathUrl.apthname);
  useEffect(() => {
    AOS.init();
  }, []);
  // const handleContextMenu = (e) => {
  //   e.preventDefault();
  // };

  // useEffect(() => {
  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);
  // const [admissionOverlay, setAdmissionOverlay] = useState(true);

  // const ShowAdmissionBanner = () => {
  //   setAdmissionOverlay(true);
  // };
  // const HideAdmissionBanner = () => {
  //   setAdmissionOverlay(false);
  // };

  return (
    <BrowserRouter>
      <HelmetProvider>
       <Main>
          {/* {admissionOverlay && (
          <AdmissionBanner onHideAdmissionBanner={HideAdmissionBanner} />
        )} */}

          {/* <BubbleAnimate /> */}
          <Routers />
          {/* <WhatsAppButton/> */}
        </Main>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;


