import React from "react";
import Modal from "../../../overLay";
import NewsLetter from "../../../../assets/docs/JSACAT_News letter - 2023.pdf";
import Iframe from "react-iframe";
const Newsletter = () => {
  return (
    <Modal>
      <Iframe
        url={NewsLetter}
        width="100%"
        height="1200"
        display="block"
        position="relative"
        style={{ minHeight: "110rem !important", maxWidth: "1200px" }}
      />
    </Modal>
  );
};

export default Newsletter;
