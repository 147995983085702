import "./loader.scss";
import Logo from "../../assets/images/logo/JsaAgri-logo-1.png";

const Loader = () => {
  return (
    <div className="loader">
      <img src={Logo} alt="jsa agri Logo" />
    </div>
  );
};

export default Loader;
