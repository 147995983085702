import Logo from "../../../assets/images/logo/JsaAgri-logo-1.png";
import { Link } from "react-router-dom";
import LogoICAR from "../../../assets/images/logo/ICAR.png";
import LogoTnau from "../../../assets/images/logo/TNAU.gif";
import LogoAgri from "../../../assets/images/logo/JsaAgri-logo-1.png";
import "./navhead.scss";

export const NavHead = () => {
  // const phoneNumber = ''; 
  return (
  //   <div className="full-screen-container">
  //   <a href="/">
  //     <img
  //       src={LogoAgri}
  //       alt="jsa college"
  //       className="full-screen-image"
  //     />
  //   </a>
  // </div>
  <div className="row text-center w-100">
  <div className="col-2 d-flex align-items-center">
    <a href="/">
      <img
        src={LogoAgri}
        width="150"
        alt="jncw college"
        className=""
        style={{ marginLeft: "8vw", maxWidth: "11vw" }}
      />
    </a>
    <div className="col-2 d-flex align-items-center">
      <a href="/">
        <img
          src={LogoTnau}
          width="150"
          alt="jncw college"
          className=""
          style={{ marginLeft: "55vw", maxWidth: "10vw" }}
        />
      </a>
    </div>
    <div className="col-2 d-flex align-items-center">
      <a href="/">
        <img
          src={LogoICAR}
          width="150"
          alt="jncw college"
          className="blinking-logo"
          style={{ marginLeft: "65vw", maxWidth: "8vw" }}
        />
      </a>
    </div>
  </div>
  <div className="col-9 p-3 " style={{ marginLeft: "-7vw" }}>
    <div className="col-12">
      <h2 className="text-component" style={{ fontSize: "2.2vw" ,color:"green", fontWeight:"bold"}}>
      JSA COLLEGE OF AGRICULTURE AND TECHNOLOGY 
      </h2>
    </div>
   
    <div className="col-12">
      <h2 style={{ fontSize: "1.2vw", color:"red" }} className="side-text">
       ( Accredited by Indian Council of Agricultural Research (ICAR), New Delhi-110 012.)
      </h2>
    </div>
    <div className="col-12">
      <h2 style={{ fontSize: "1.2vw", color:"#23C709" }} className="side-text">
      {/* Accredited by F.No.Edn./EQR/1/80/2021-Accre.dt.10.07.2024. */}
      </h2>
    </div>
    <div className="col-12">
      <h2 style={{ fontSize: "1.2vw", color:"#23C709" }} className="side-text">
      (Affiliated to Tamil Nadu Agricultural University, Coimbatore-3)
      </h2>
    </div>

    <div className="col-12">
      <h2 style={{ fontSize: "1vw" }} className="side-text">
      The College is approved by Government of Tamilnadu Vide G.O.No.198 dt.28.09.2015
      </h2>
    </div>
    <div className="col-12">
      <h2 style={{ fontSize: "1vw" }} className="side-text">
   Part of JSA GROUP OF INSTITUTIONS
      </h2>
    </div>
    <div className="col-12">
      <h2 style={{ fontSize: "1vw" }} className="text-success">
      Ma.Podaiyur, Avatti Post & Bus Stop, Ulundurpet to Trichy NH-Onroad, Cuddalore (Dt),
      Tamil Nadu – 606 108.
      </h2>
    </div>
    
  </div>
</div> 


);
};
