import React, { useState } from "react";
import Modal from "../overLay";
import "./admissionBanner.scss";
import Admission_Banner from "../../assets/images/admission/Admission_Banner.jpeg";
import NewsLetter from "../../assets/docs/JSACAT_News letter - 2023.pdf";
import Iframe from "react-iframe";
import  Icar from "../../assets/images/admission/icar.jpg"

const AdmissionBanner = (props) => {
  const [showNewsletter, setShowNewsletter] = useState(false);
  return (
    <Modal onHideAdmissionBanner={props.onHideAdmissionBanner}>
      <div className="position-relative admission-banner">
        <div className="position-absolute end-0">
          <button
            className="btn btn-danger position-absolute top-0 start-100 translate-middle badge rounded-pill"
            onClick={props.onHideAdmissionBanner}
          >
            X
          </button>
        </div>
        {showNewsletter ? (
          <Iframe
            url={NewsLetter}
            width="100%"
            height="1200"
            display="block"
            position="relative"
            style={{ minHeight: "110rem !important", maxWidth: "1200px" }}
          />
        ) : (
          <img
            src={Icar}
            alt="Admissions open "
            className="w-100"
          
          />
        )}
      </div>
    </Modal>
  );
};

export default AdmissionBanner;
