import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate, } from "react-router-dom";
import Loader from "./components/loader";
import Newsletter from "./components/pages/home/newsLetter";


const Routers = () => {
  const Home = lazy(() => import("./components/pages/home"));
  const Chairman = lazy(() => import("./components/pages/aboutUs/chairman"));
  const Secretary = lazy(() => import("./components/pages/aboutUs/secretary"));
  const Principal = lazy(() => import("./components/pages/aboutUs/pricipal"));
  const AboutCollege = lazy(() =>
    import("./components/pages/aboutUs/aboutOurCollege")
  );
  const GoverningCouncil = lazy(() =>
    import("./components/pages/aboutUs/governingCouncil")
  );
  const VisionMission = lazy(() =>
    import("./components/pages/aboutUs/visionMission")
  );
  const Goal = lazy(() => import("./components/pages/aboutUs/goal"));
  const GovtApprovalOrder = lazy(() =>
    import("./components/pages/aboutUs/TNGovtOrder")
  );
  const AffiliationOrder = lazy(() =>
    import("./components/pages/aboutUs/TNAUAffiliationOrder")
  );
  const Courses = lazy(() =>
    import("./components/pages/academics/courseOffered")
  );
  const Syllabus = lazy(() => import("./components/pages/academics/syllabus"));
  const RulesAndRegulations = lazy(() =>
    import("./components/pages/academics/rulesAndRegulations")
  );
  const Eligibility = lazy(() =>
    import("./components/pages/admission/eligibility")
  );
  const AdmissionProcedure = lazy(() =>
    import("./components/pages/admission/admission-procedure")
  );
  const FeeStructure = lazy(() =>
    import("./components/pages/admission/feeStructure")
  );
  const CollegeMainBuilding = lazy(() =>
    import("./components/pages/infrastructure/collegeMainBuilding")
  );
  const Library = lazy(() =>
    import("./components/pages/infrastructure/library")
  );
  const Laboratories = lazy(() =>
    import("./components/pages/infrastructure/laboratories")
  );
  const ComputerLab = lazy(() =>
    import("./components/pages/infrastructure/computerLab")
  );
  const MeteorologicalObservatory = lazy(() =>
    import("./components/pages/infrastructure/meteorologicalLab")
  );
  const AnimalHusbandary = lazy(() =>
    import("./components/pages/infrastructure/animalHusbandary")
  );
  const FacultyHome = lazy(() => import("./components/pages/faculty/home"));
  const FacultyDetails = lazy(() =>
    import("./components/pages/faculty/facultyDetails")
  );

  const StudentWelfare = lazy(() =>
    import("./components/pages/studentsWelfare/welfare")
  );
  const InternalComplaintsCommittee = lazy(() =>
    import("./components/pages/studentsWelfare/internalComplaintsCommittee")
  );
  const AntiRaggingCommittee = lazy(() =>
    import("./components/pages/studentsWelfare/antiRaggingCommittee")
  );
  const GraduationDay = lazy(() =>
    import("./components/pages/events/graduationDay")
  );
  const FreshersDay = lazy(() =>
    import("./components/pages/events/freshersDay")
  );
  const NSS = lazy(() => import("./components/pages/events/nss"));
  const HostelDay = lazy(() => import("./components/pages/events/hostelDay"));
  const RepublicDay = lazy(() =>
    import("./components/pages/events/republicDay")
  );
  const IndependenceDay = lazy(() =>
    import("./components/pages/events/independenceDay")
  );
  const WomensDay = lazy(() => import("./components/pages/events/womensDay"));
  const ExperientialLearningProgramme = lazy(() =>
    import("./components/pages/events/experientialLearningProgramme")
  );
  const Moments2024 = lazy(() => import ("./components/pages/gallery/2024.js"));
  const Moments2023  = lazy(() => import("./components/pages/gallery/2023"));
  const Moments2022  = lazy(() => import("./components/pages/gallery/2022"));
  const Moments2021  = lazy(() => import("./components/pages/gallery/2021"));
  const Moments2020  = lazy(() => import("./components/pages/gallery/2020"));
  const Moments2019  = lazy(() => import("./components/pages/gallery/2019"));
  const Videos      = lazy (() => import("./components/pages/gallery/video.js"));
  const PressMedia = lazy(() => import("./components/pages/gallery/PressMedia"));

  const Portfolio = lazy(() => import("./components/pages/portfolio"));
  const NewsAndUpcoming =lazy(() => import("./components/pages/newsAndupcoming/index.js"));
  const Placements = lazy(() => import("./components/pages/acheivements/placement"));
  const HigherEducation = lazy(() =>
    import("./components/pages/acheivements/higherEducation")
  );
  const Sports = lazy(() => import("./components/pages/acheivements/sports"));
  const ContactUs = lazy(() => import("./components/pages/contactUs"));
  const Reports = lazy(() => import("./components/pages/Reports/reports.js"));
  const OnlinePayment = lazy(() => import("./components/pages/onlinePayment"));
  const LatestEvent = lazy(() =>
    import("./components/pages/latestEvent/index.js")
  );
  // const ErrorPage = lazy(() => import("./components/errorPage"));
// const WhatsAppButton=lazy(() => import ("./components/whatsapp/WhatsAppButton.js"));
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        
        <Route exact path="/" element={<Home />} />
        <Route exact path="/newsletter" element={<Newsletter />} />

        <Route exact path="/chairman" element={<Chairman />} />
        <Route exact path="/secretary" element={<Secretary />} />
        <Route exact path="/principal" element={<Principal />} />
        <Route exact path="/about-us" element={<AboutCollege />} />
        <Route exact path="/governingCouncil" element={<GoverningCouncil />} />
        <Route exact path="/vision-mission" element={<VisionMission />} />
        <Route exact path="/goal" element={<Goal />} />

        <Route
          exact
          path="/tn-govt-approval-order"
          element={<GovtApprovalOrder />}
        />
        <Route
          exact
          path="/tnau-affliation-order"
          element={<AffiliationOrder />}
        />
        <Route exact path="/courses" element={<Courses />} />
        <Route exact path="/syllabus" element={<Syllabus />} />
        <Route
          exact
          path="/rules-and-regulations"
          element={<RulesAndRegulations />}
        />
        <Route exact path="/eligibility" element={<Eligibility />} />
        <Route
          exact
          path="/admission-procedure"
          element={<AdmissionProcedure />}
        />
        {/* <Route exact path="/fee-structure" element={<FeeStructure />} /> */}
        <Route
          exact
          path="/college-main-building"
          element={<CollegeMainBuilding />}
        />
        <Route exact path="/library" element={<Library />} />
        <Route exact path="/laboratories" element={<Laboratories />} />

        <Route exact path="/computer-lab" element={<ComputerLab />} />

        <Route
          exact
          path="/meteorological-observatory"
          element={<MeteorologicalObservatory />}
        />
        <Route exact path="/animal-husbandary" element={<AnimalHusbandary />} />
        <Route exact path="/faculty/cropManagement" element={<FacultyHome />} />
        <Route
          exact
          path="/faculty/cropImprovement"
          element={<FacultyHome />}
        />
        <Route exact path="/faculty/cropProtection" element={<FacultyHome />} />

        <Route exact path="/faculty/horticulture" element={<FacultyHome />} />
        <Route exact path="/faculty/socialSciences" element={<FacultyHome />} />
        <Route exact path="/faculty/:id" element={<FacultyDetails />} />

        <Route exact path="/students-welfare" element={<StudentWelfare />} />
        <Route
          exact
          path="/internal_complaints_committee"
          element={<InternalComplaintsCommittee />}
        />
        <Route
          exact
          path="/anti-ragging-committee"
          element={<AntiRaggingCommittee />}
        />
        <Route exact path="/graduation-day" element={<GraduationDay />} />
        <Route exact path="/freshers-day" element={<FreshersDay />} />
        <Route exact path="/nss" element={<NSS />} />
        <Route exact path="/hostel-day" element={<HostelDay />} />
        <Route exact path="/republic-day" element={<RepublicDay />} />
        <Route exact path="/independence-day" element={<IndependenceDay />} />
        <Route exact path="/womens-day" element={<WomensDay />} />
        <Route
          exact
          path="/Experiential-Learning-Programme"
          element={<ExperientialLearningProgramme />}
        />
  <Route exact path="/gallery/2024" element={<Moments2024/>} />
        <Route exact path="/gallery/2023" element={<Moments2023 />} />
        <Route exact path="/gallery/2022" element={<Moments2022 />} />
        <Route exact path="/gallery/2021" element={<Moments2021 />} />
        <Route exact path="/gallery/2020" element={<Moments2020 />} />
        <Route exact path="/gallery/2019" element={<Moments2019 />} />
        <Route exact path="/gallery/videos" element={<Videos/>} />
        <Route exact path="/gallery/media" element={<PressMedia/>} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/news&upcoming" element={<NewsAndUpcoming/>} />
        <Route exact path="/placements" element={<Placements />} />
        <Route exact path="/higher-education" element={<HigherEducation />} />
        <Route exact path="/sports" element={<Sports />} />
        <Route exact path="/latestevent" element={<LatestEvent />} />
        <Route exact path="/online-payment" element={<OnlinePayment />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/reports" element={<Reports />} />
       
        {/* <Route exact path="*" element={<ErrorPage />} ststus={404} /> */}
        <Route exact path="*" element={<Navigate to="/404" />} />
        {/* <Route exact path="/404" element={<ErrorPage />} /> */}
     
        {/* <Route path="/whatsapp" component={WhatsAppButton} /> */}
  
      </Routes>
    </Suspense>
  );
};

export default Routers;
