import React from "react";
import { useLocation } from "react-router-dom";
import ErrorPage from "../errorPage";
import Footer from "../footer";
import NavBar from "../navbar/navBar";

const Main = (props) => {
  const location = useLocation();
  const pathUrl = location.pathname;
  return (
    <>
      {pathUrl !== "/404" ? (
        <>
          <NavBar />
          {props.children}
          <Footer />
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
export default Main;
