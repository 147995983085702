import { AdmissionMarquee } from "./admissionMrq";
import { NavHead } from "./navHead";
import ApplicationForm from "../../assets/docs/application-2023.pdf";
import Logo from "../../assets/images/logo/JsaAgri-logo-1.png"
import "./navBar.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [pagesDropdownOpen, setPagesDropdownOpen] = useState(false);
  const [AcademicsDropdownOpen, setAcademicsDropdownOpen] = useState(false);

  const toggleAboutDropdown = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
  };

  const togglePagesDropdown = () => {
    setPagesDropdownOpen(!pagesDropdownOpen);
  };
  const toggleAcademicsDropdown = () => {
    setAcademicsDropdownOpen(!AcademicsDropdownOpen);
  };
  return (
    <div id="nav">
      <AdmissionMarquee />
      <NavHead />
      <nav className="navbar navbar-expand-lg  navbar-light  pt-0 pb-0 ">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div className="collapse navbar-collapse" id="navCollapse">
          <ul className="navbar-nav mr-auto d-flex flex-wrap ps-2">
            <li className="nav-item">
              <a href="/" className="nav-link">
                Home
              </a>
            </li>

            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="dropdown-toggle nav-link"
              >
                About us <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/chairman">
                    Chairman
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/secretary">
                    Secretary
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/principal">
                    Principal
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/about-us">
                    About the College
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/governingCouncil">
                    Governing council
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/vision-mission">
                    Vision &amp; Mission
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/goal">
                    Goal
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/tn-govt-approval-order">
                    TN Government's Approval order
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/tnau-affliation-order">
                    TNAU's Affiliation Order
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Academics <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/courses">
                    Course Offered
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/syllabus">
                    Syllabus
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/rules-and-regulations">
                    Rules and Regulations
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Admission <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/eligibility">
                    Eligibility
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/admission-procedure">
                    Admission Procedure
                  </a>
                </li>
                {/* <li>
                  <a className="dropdown-item" href="/fee-structure">
                    Fee Structure
                  </a>
                </li> */}
                <li>
                  <a
                    target="_blank"
                    className="dropdown-item"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeZxcJeVBJrzsBiwXSC3POLhWl46Zx3gotAEiTtkEriulH8tA/viewform"
                    rel="noreferrer"
                  >
                    Admission Enquiry Form
                  </a>
                </li>
                {/* <li>
                  <a
                    className="dropdown-item"
                    target="_blank"
                    href={ApplicationForm}
                    rel="noreferrer"
                  >
                    Download Application Form
                  </a>
                </li> */}
              </ul>
            </li>

            <li className="dropdown nav-item">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Infrastructure <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/college-main-building">
                    College Main Building
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/library">
                    Library
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/laboratories">
                    Laboratories
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/computer-lab">
                    Computer Lab
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/meteorological-observatory"
                  >
                    Meteorological Observatory
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/animal-husbandary">
                    Animal Husbandary
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown nav-item">
              <div
                role="button"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Faculty <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li className="dropdown">
                  <a className="dropdown-item" href="/faculty/cropManagement">
                    {" "}
                    Crop management
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/faculty/cropImprovement">
                    {" "}
                    Crop improvement
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/faculty/cropProtection">
                    {" "}
                    Crop protection
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/faculty/horticulture">
                    {" "}
                    Horticulture
                  </a>
                </li>
                <li className="dropdown">
                  <a className="dropdown-item" href="/faculty/socialSciences">
                    {" "}
                    Social Sciences
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Student Welfare <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/students-welfare">
                    Student Welfare
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="/internal_complaints_committee"
                  >
                    Internal complaints committee
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/anti-ragging-committee">
                    {" "}
                    Anti Ragging Committee
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Events <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="/Experiential-Learning-Programme"
                  >
                    Experiential Learning Programme
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/graduation-day">
                    Graduation Day
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/freshers-day">
                    Fresher's Day
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/nss">
                    NSS
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/hostel-day">
                    Hostel Day
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/republic-day">
                    Republic Day
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/independence-day">
                    Independence Day
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/womens-day">
                    Women's Day
                  </a>
                </li>
              </ul>
            </li>

            
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Gallery<b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
              <li >
              <a href="/portfolio" className="dropdown-item">
                Portfolio
              </a>
            </li>
              <li>
                  <a className="dropdown-item" href="/gallery/2024">
                    2024
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2023">
                    2023
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2022">
                    2022
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/gallery/2021">
                    2021
                  </a>
                </li>
                <li>
                  <a

                    className="dropdown-item"
                    href="/gallery/2020"
                  >
                    2020
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
               
                    href="/gallery/2019"
                  >
                    2019
                  </a>
                </li>
          
                <li>
                  <a
                    className="dropdown-item"
               
                    href="/gallery/videos"
                  >
                    Videos
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"

                    href="/gallery/media"

                  >
                    Press News and Tv Release
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="/news&upcoming" className="nav-link">
              News
              </a>
            </li>
            <li className="nav-item dropdown">
              <div
                role="button"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                Achievements <b className="caret"></b>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/placements">
                    Placements
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/higher-education">
                    Higher education
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/sports">
                    Sports
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a href="/online-payment" className="nav-link">
                Online Payment{" "}
              </a>
            </li>
            <li className="nav-item">
              <a href="/contact-us" className="nav-link">
                Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a href="/latestevent" className="nav-link">
                <span class="badge text-bg-danger">Latest Event</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="/reports" className="nav-link">
                Reports
              </a>
            </li>
          </ul>
        </div>
      </nav>
   
    
    
    </div>

  );
};
export default NavBar;
