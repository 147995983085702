import "./index.scss";
import ClickImg from "../../../assets/images/home/click.gif";
export const AdmissionMarquee = () => {
  return (
    <div className="marquee">
      <ul>
        <li>
          <strong>Admission Open for the Academic Year 2024-2025</strong>
          &nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the aspiring
          students are requested to enrol your online registration in TNAU &amp;
          also{" "}
          <a
            target="_blank"
            href="https://forms.gle/ZXV1AXd9xrxGiJSbA"
            rel="noreferrer"
          >
            {" "}
            <img src={ClickImg} alt="jsaagri" />
          </a>{" "}
          &nbsp; &nbsp; to enrol in our college
          &nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>"JSA COLLEGE OF AGRICULTURE AND TECHNOLOGY" </strong>{" "}
          <i>Located In the National Highways (Chennai to Trichy) ,</i>
          Avatti, Tholudur, Cuddalore (Dist.), Tamil Nadu – 606 108
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; Contact –
          <a href="tel:+919361909163" className="contact">
            {" "}
            +91 9361 909 163
          </a>
          /
          <a href="tel:+919361909073" className="contact">
            {" "}
            +91 9361 909 073
          </a>
          /
          <a href="tel:+919566223456" className="contact">
            {" "}
            +91 9566 223 456
          </a>
          /
          <a href="tel:+919443373325" className="contact">
            {" "}
            +91 9443373325
          </a>
          /
          <a href="tel:+917305057759" className="contact">
            {" "}
            +91 73050 57759
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>ICAR accreditation is Being Obtained</strong> ** State level
          No.1 Leading Agriculture College for Research , Innovation, Creativity
          and Discipline
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; Our Alumni
          got 100% placement in Competitive exams of IAS, Central, State
          Governments and Banks
          &nbsp;&nbsp;&nbsp;&nbsp;**&nbsp;&nbsp;&nbsp;&nbsp; The Students are
          doing PG in various reputed Indian &amp; International Universities.
        </li>
      </ul>
    </div>
  );
};
